import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  if (window.location.pathname == '/') {
    smoothScroll('a[href*="/#"]', { offset: 150 })
  }

  $('.burger-menu').click(function () {
    $(this).toggleClass('open')
  })

  if ($(window).width() <= 991) {
    $('.team-member').click(function () {
      $(this).toggleClass('show')
    })
  } else {
    $('.team-member').hover(function () {
      $(this).toggleClass('show')
    })
  };

  if ($('.team-member.fixDescription')) {
    $('.team-member.fixDescription').hover(function () {
      $(this).removeClass('show')
    })
  }

  $(document).scroll(function () {
    const scroll = $(this).scrollTop()
    if (scroll > 0) {
      $('.header').addClass('fixed')
    } else {
      $('.header').removeClass('fixed')
    };
  })

  $('#menu li a').click(function () {
    $('.burger-menu').removeClass('open')
  })
})

$('.carousel.gallery-slider .neos-contentcollection').slick({
  dots: false,
  arrows: true,
  infinite: true,
  speed: 1200,
  cssEase: 'linear',
  ease: 'ease-in',
  slidesToShow: 1,
  autoplay: false,
  centerMode: false,
  useTransform: true,
  variableWidth: true,
  pauseOnHover: true,
  touchMove: true,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        centerMode: true
      }
    },
    {
      breakpoint: 500,
      settings: {
        variableWidth: false,
        speed: 500
      }
    }
  ]
})

$('.carousel.teaser-slider .neos-contentcollection').slick({
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  cssEase: 'linear',
  ease: 'ease-in',
  slidesToShow: 4,
  slidesToScroll: 4,
  autoplay: false,
  centerMode: false,
  touchMove: false,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        touchMove: true
      }
    },
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        infinite: true,
        speed: 500
      }
    }
  ]
})
